<template>
  <div>
    <div
      :id="'item_question-group' + qgr.id"
      class="item_question box_question_example item_question-group"
    >
      <div class="row">
        <div class="col-lg-6">
          <div class="custom_form_question">
            <div class="heading_question">
              <div class="text-content">
                <p v-html="qgr.name" />
                <div
                  v-if="qgr.explain"
                  class="transcriptGroup"
                >
                  <div
                    class="transcriptGroup_title"
                    :class="transcriptShow ? 'actived' : ''"
                  >
                    <span @click="transcriptShow =! transcriptShow">Giải thích</span>
                  </div>
                  <div
                    v-if="transcriptShow"
                    class="transcriptGroup_body"
                  >
                    <p v-html="qgr.explain" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="qgr.images">
              <template v-for="(image) in qgr.images">
                <img
                  :src="image.path"
                  alt=""
                >
              </template>
            </div>

            <p v-if="qgr.audio">
              <audio
                class="mt-2"
                controls
              >
                <source
                  :src="storageUrl + qgr.audio.url"
                  type="audio/ogg"
                >
              </audio>
            </p>
          </div>
        </div>
        <div
          v-if="qgr.questions"
          class="col-lg-6"
        >
          <div
            class="scroll-chill-question"
          >
            <div
              v-for="(q, key) in qgr.questions"
              :key="key"
              class="item_question__child"
            >
              <span
                :id="'check-correct-question-' + q.id"
                class="float-right crc"
              >{{ checkCorrectQuestion(q) }}</span>
              <div
                :id="'heading_question_' + qgr.id + '_' + q.id"
                class="heading_question"
              >
                <span class="number_quiz">{{ q.stt }}</span>
                <div class="text-content">
                  <p v-html="q.name" />
                  <div
                    v-if="q.explain"
                    class="transcriptGroup"
                  >
                    <div
                      class="transcriptGroup_title"
                      :class="transcriptShowChild ? 'actived' : ''"
                    >
                      <span @click="transcriptShowChild =! transcriptShowChild">Giải thích</span>
                    </div>
                    <div
                      v-if="transcriptShowChild"
                      class="transcriptGroup_body"
                    >
                      <p v-html="q.explain" />
                    </div>
                  </div>
                </div>
              </div>
              <p
                v-if="q.image"
                class="text-center"
              >
                <img
                  :src="storageUrl + q.image.url"
                  alt=""
                >
              </p>
              <p v-if="q.audio">
                <audio
                  class="mt-2"
                  controls
                >
                  <source
                    :src="storageUrl + q.audio.url"
                    type="audio/ogg"
                  >
                </audio>
              </p>
              <ul
                v-if="q.answers"
                class="answer_question"
              >
                <template v-for="(ansChild) in q.answers">
                  <li
                    :id="answers.includes(ansChild.id) && !ansChild.is_correct ? 'failse_question' : ''"
                    class="answer_question__item"
                    :class="ansChild.is_correct ? 'success_question' : ''"
                  >
                    <label class="label_custom">
                      <span v-html="ansChild.name" />
                      <input
                        type="radio"
                        :name="'answer-child'+q.id"
                        :disabled="true"
                        :checked="answers.includes(ansChild.id)"
                        :value="ansChild.id"
                      >
                      <span class="checkmark" />
                    </label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GroupQuestion',
  props: {
    qgr: {
      type: Object,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      type_step: 1,
      storageUrl: process.env.STORAGE_BASE_URL,
      transcriptShow: false,
      transcriptShowChild: false,
      heightQgr: 0,
      frm: {
        lesson_skill_id: null,
        question_id: null,
        answer_id: null,
      },
    }
  },
  methods: {
    checkCorrectQuestion(q) {
      let textCorrect = 'Bỏ qua'
      const { answers } = q
      answers.map(ans => {
        if (this.answers.includes(ans.id)) {
          if (ans.is_correct) {
            textCorrect = 'Đúng'
          } else {
            textCorrect = 'Sai'
          }
        }
      })
      return textCorrect
    },
  },
}
</script>
<style lang="scss" scoped>
.item_question-group{
  .custom_form_question{
    background: #F8F9FA;
    padding: 15px 10px;
  }
}
</style>
