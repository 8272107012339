<template>
  <b-row>
    <b-col
      cols="12"
    >
      <div class="card card-border">
        <div class="card-body">
          <div class="row row-center">
            <div class="col-md-8">
              <div
                v-if="correct_answer_data"
                class="part_group demo-vertical-spacing"
              >
                <div v-for="(cr) in correct_answer_data">
                  <label>{{ cr.name }}</label>
                  <div class="box_progressbar__part">
                    <b-progress
                      :value="parseInt(cr.totalPoint)"
                      :max="parseInt(cr.maxPoint)"
                      precision="2"
                      show-progress
                      variant="warning"
                      class="progress-bar-warning"
                    >
                      <b-progress-bar :value="parseInt(cr.totalPoint)">
                        <strong>{{ parseInt(cr.totalPoint) }}/{{ parseInt(cr.maxPoint) }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                  <hr>
                </div>
              </div>
              <br>

            </div>
            <div class="col-md-4 sibar_total_point">
              <div class="text-right mb-2" />
              <div class="text-center">
                <div class="c100 big mb-4 number2">
                  <card-analytic-goal-overview
                    :title="''"
                    :completed="'Tổng điểm'"
                    :max="totalPointExam"
                    :result="totalPoint"
                    :percent="percentQuestionWork"
                    :title-progress="'Tổng điểm'"
                    :title-complete="'Điểm tối đa'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="card card-border">
        <div class="card-body">
          <h5><strong>Phân tích chi tiết</strong></h5>
          <div class="Group_Quiz_Test Result_Quiz_Test">
            <div class="list_question_with_lab">
              <ul class="list-part">
                <li
                  v-for="(part, key) in question_data"
                  :key="key"
                  :class="activePart === part.id ? 'active' : '' + 'success__part'"
                  @click="show_listItemQuiz(part)"
                  v-html="part.name"
                />
              </ul>
              <div class="table-responsive">
                <table class="table b-table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        width="300"
                      >
                        Phân loại câu hỏi
                      </th>
                      <th
                        scope="col"
                        width="150"
                      >
                        Số câu đúng
                      </th>
                      <th
                        scope="col"
                        width="150"
                      >
                        Số câu sai
                      </th>
                      <th
                        scope="col"
                        width="150"
                      >
                        Số câu bỏ qua
                      </th>
                      <th
                        scope="col"
                        width="150"
                      >
                        Độ chính xác
                      </th>
                      <th width="300">
                        Danh sách câu hỏi
                      </th>
                      <th>Nhận xét</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rv) in reviews">
                      <td>{{ rv.nameTagReview }}</td>
                      <td>{{ rv.countQuestionCorrect }}</td>
                      <td>{{ rv.countQuestionWrong }}</td>
                      <td>{{ rv.countQuestionOmitted }}</td>
                      <td>{{ rv.percentCorrect }}%</td>
                      <td>
                        <template v-for="(q) in rv.listQuestion">
                          <span
                            v-if="q['status'] === 1"
                            class="item-question-tb question-correct"
                          >{{ q['stt'] }}</span>
                          <span
                            v-if="q['status'] === 2"
                            class="item-question-tb question-wrong"
                          >{{ q['stt'] }}</span>
                          <span
                            v-if="q['status'] === 0"
                            class="item-question-tb question-skip"
                          >{{ q['stt'] }}</span>
                        </template>
                      </td>
                      <td>
                        <div class="note-content">
                          <template v-html="rv.comment" />
                        </div>
                      <!--                            <p class="note-content" v-html="rv.comment"/>-->
                      <!--                            <button type="button" class="btn-show-note" @click="showModalNote()">Xem thêm</button>-->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="card card-border">
        <div class="card-body">
          <div class="list_question_with_lab">
            <template v-for="(q) in questionList">
              <GroupQuestionComponent
                v-if="q.questions"
                :qgr="q"
                :answers="answers"
              />
              <OnlyQuestionComponent
                v-else
                :question="q"
                :answers="answers"
              />
            </template>
          </div>
        </div>
      </div>
    </b-col>

  </b-row>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BCardBody, BCollapse, BProgress, BProgressBar, BRow, BCol,
} from 'bootstrap-vue'
import CardAnalyticGoalOverview from '@/views/card/card-analytic/CardAnalyticGoalOverview'
import OnlyQuestionComponent from '@/views/library/OnlyQuestion'
import GroupQuestionComponent from '@/views/library/QuestionGroup'

export default {
  components: {
    CardAnalyticGoalOverview,
    BCard,
    BProgressBar,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCollapse,
    BRow,
    BProgress,
    OnlyQuestionComponent,
    GroupQuestionComponent,
    BCol,
  },
  inheritAttrs: false,
  props: {
  },
  data() {
    return {
      parentID: '',
      isLoading: false,
      id: this.$route.params.id,
      homeWorkHistory: [],
      totalPoint: 0,
      answers: [],
      reviews: [],
      correct_answer_data: [],
      correct_answer: [],
      question_data: [],
      time_remain: 0,
      questionList: [],
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      totalPointExam: 0,
      totalQuestion: 0,
      question_complete: [],
      percentQuestionWork: 0,
      activePart: 0,
    }
  },
  created() {
    this.loadItem()
  },
  methods: {
    loadItem() {
      this.isLoading = true
      this.$http.get(`/user/do-homework/result/${this.id}`)
        .then(res => {
          const { data } = res
          this.totalPoint = data.total_point
          this.answers = JSON.parse(data.answers)
          this.correct_answer_data = JSON.parse(data.correct_answer_data)
          this.correct_answer = JSON.parse(data.correct_answer)
          this.question_data = JSON.parse(data.question_data)
          this.time_remain = parseInt(data.time_remain) * 60 * 1000
          this.questionList = this.question_data[0].questions
          this.show_listItemQuiz(this.question_data[0])
          this.question_complete = JSON.parse(data.question_complete)
          let totalPointExam = 0
          if (this.correct_answer_data) {
            // Đây là bài thi
            this.correct_answer_data.map(v => {
              this.totalQuestion += v.totalQuestion
              totalPointExam += parseInt(v.maxPoint)
            })
            this.totalPointExam = totalPointExam
          } else {
            // Đây là bài tập
            this.question_data.map(q => {
              this.totalQuestion += q.questions.length
            })
          }

          this.percentQuestionWork = Math.round((this.totalPoint / this.totalPointExam) * 100)
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    show_listItemQuiz(part) {
      this.questionList = part.questions
      this.activePart = part.id
      this.reviews = part.reviews
    },
  },
}
</script>

<style lang="scss" scoped>
.card-border{
  border: 1px dashed #FF7F00;
  border-radius: 10px;
}
.Result_Quiz_Test {
  margin-top: 40px;
  .answer_question {

    padding-left: 0 !important;

    &__item {
      padding-left: 45px !important;
    }

    .success_question {
      background: rgba(52, 168, 83, 0.1);
      border-radius: 8px;
      padding-left: 45px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 10px;
        top: 10px;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-size: 22px;
        color: #3fb744;
        width: 18px;
        height: 18px;
        fill: #3fb744;
        //background: url('../images/toeic/check_success.png');
        background-size: contain;
      }
    }

    .failse_question {
      background: #FCE9E9;
      border-radius: 8px;
      padding-left: 45px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 10px;
        top: 10px;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        fill: #3fb744;
        //background: url('../images/toeic/check_failse.png');
        background-size: contain;
      }
    }
  }
}

.row-center{
  align-items: center;
  justify-items: center;
}

.sibar_total_point {
  border-left: 2px solid #f3f3f3;
}
.text-content {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.list-part{
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  li{
    padding: 5px;
    background: #F9F9F9;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-items: center;
    font-size: 13px;
    cursor: pointer;

    &:hover{
      color: #ff9f43;
    }

    &.active{
      background: #ff9f43;
      color: #fff;
    }
  }
}
.heading_question{
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}
span.number_quiz {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 30px;
  background: #000;
  color: #fff;
  font-weight: 600;
  border-radius: 40px;
  font-size: 13px;
  position: relative;
  top: -5px;
}
.item_question__child{
  margin-left: 25px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #ddd;
}
.item-question-tb{
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: #ffab574f;
  color: #FF7F00;
  margin-right: 5px;
  margin-top: 5px;
}
ul.answer_question {
  list-style: none;
  li{
    margin-bottom: 15px;
    padding: 10px 0;
    *{
      margin-bottom: 0;
    }

    &.answer_question__item{
      &:after{
        display: none !important;
      }
    }
  }
}
.question-skip {
  background: #ffab574f !important;
  color: #FF7F00 !important;
}
.question-correct{
  background: rgba(60, 222, 96, 0.08) !important;
  color: #2FB54C !important;
}
.question-wrong{
  background: rgba(255, 0, 0, 0.08) !important;
  color: #E31212 !important;
}
</style>
